import { createContext, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { FrontendConfig } from "@/graphql"
import { readJson } from "@/services/jsonTransform"
import { RolePolicyModel } from "@/shared/policies/types"

type ConfigContextType = {
  setUiConfig: React.Dispatch<React.SetStateAction<FrontendConfig | undefined>>
  setForceRefreshUiConfig: (_: () => () => Promise<void>) => void
  forceRefreshUiConfig: () => () => Promise<void>
  getConfigNumber: (category: string, key: string) => number | undefined
  getConfigString: (category: string, key: string) => string | undefined
  getConfigBoolean: (category: string, key: string) => boolean | undefined
  getConfigEnum: (category: string, key: string) => any | undefined
  permissions: RolePolicyModel
  language: string
}

export const ConfigContext = createContext<ConfigContextType | undefined>(
  undefined,
)

type ConfigProviderProps = {
  children: React.ReactNode
}

type ConfigValueType = number | string | boolean

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const { i18n } = useTranslation()
  const [uiConfig, setUiConfig] = useState<FrontendConfig>()

  const [permissions, setPermissions] = useState<RolePolicyModel>({})
  const [language, setLanguage] = useState("en")

  const [forceRefreshUiConfig, setForceRefreshUiConfig] = useState<
    () => () => Promise<void>
  >(() => async () => {})

  const getConfig = useCallback(
    <T extends ConfigValueType>(
      category: string,
      key: string,
    ): T | undefined => {
      const config = uiConfig?.configs?.find(
        (config) => config?.name === category,
      )
      if (!config) return undefined
      return config.values[key] as T
    },
    [uiConfig],
  )

  useEffect(() => {
    if (!uiConfig?.permissions) {
      setPermissions({})
    } else {
      setPermissions(readJson(uiConfig.permissions) as RolePolicyModel)
    }

    if (uiConfig?.language?.iso639_1) {
      i18n.changeLanguage(uiConfig.language.iso639_1)
      setLanguage(i18n.language)
    }
  }, [i18n, uiConfig, setPermissions, setLanguage])

  const getConfigNumber = useCallback(
    (category: string, key: string) => {
      return getConfig<number>(category, key)
    },
    [getConfig],
  )

  const getConfigString = useCallback(
    (category: string, key: string) => {
      return getConfig<string>(category, key)
    },
    [getConfig],
  )

  const getConfigBoolean = useCallback(
    (category: string, key: string) => {
      return getConfig<boolean>(category, key)
    },
    [getConfig],
  )

  const getConfigEnum = useCallback(
    (category: string, key: string) => {
      return getConfig<string>(category, key)
    },
    [getConfig],
  )

  const contextValue: ConfigContextType = useMemo(() => {
    return {
      setUiConfig,
      setForceRefreshUiConfig,
      forceRefreshUiConfig,
      getConfigNumber,
      getConfigString,
      getConfigBoolean,
      getConfigEnum,
      permissions,
      language,
    }
  }, [
    setUiConfig,
    setForceRefreshUiConfig,
    forceRefreshUiConfig,
    getConfigNumber,
    getConfigString,
    getConfigBoolean,
    getConfigEnum,
    permissions,
    language,
  ])

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  )
}
