export const Address = {
  id: true,
  title: true,
  street1: true,
  street2: true,
  city: true,
  state: true,
  country: true,
  zip: true,
  fullAddress: true,
  addressType: true,
  clientId: true
}
export const BilingualSet = {
  id: true,
  createdAt: true,
  updatedAt: true,
  sourceColumn: true,
  targetColumn: true,
  excludedRows: true
}
export const BillingType = {
  id: true,
  createdAt: true,
  updatedAt: true,
  title: false
}
export const BitextImport = {
  id: true,
  createdAt: true,
  status: false,
  note: false,
  format: false,
  tmTags: true,
  metadata: true,
  importLangMappings: true,
  bitextImportFiles: true,
  progress: true,
  createdBy: true,
  taskExecutions: true
}
export const BitextImportConversion = {
  id: true,
  bitextHash: false,
  contentHash: false,
  tmBitext: false,
  bitextImportFile: false
}
export const BitextImportFile = {
  id: true,
  importFileName: false,
  importFileSize: true,
  importContentType: true,
  progress: true,
  status: false,
  bitextImportId: false,
  bitextImportConversions: true
}
export const BusinessLine = {
  id: true,
  createdAt: true,
  updatedAt: true,
  title: false
}
export const CalendarInterval = {
  id: true,
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  hour: false,
  minute: false,
  duration: false,
  position: false
}
export const CalendarIntervalRange = {
  id: true,
  name: false,
  startDate: true,
  endDate: true,
  schedulerResource: true,
  timeZone: true,
  calendarIntervals: true,
  schedulerResourceId: true
}
export const Client = {
  id: true,
  code: false,
  name: false,
  active: false,
  contractEnds: true,
  ratesNote: true,
  category: true,
  rateSchema: true,
  providers: true,
  website: true,
  note: true,
  noteCoordo: true,
  contractNumber: true,
  contractBegin: true,
  clientWords: true,
  deadlineRequired: true,
  since: true,
  finalFileNameTemplate: true,
  domain: true,
  industry: true,
  manager: true,
  manager2: true,
  billingType: true,
  invoiceCurrency: true,
  paymentType: true,
  taxSchema: true,
  divisions: true,
  addresses: true,
  tmTags: true,
  tmDefaultAssetTags: true,
  tmDefaultAnalysisTags: true,
  tmDefaultExcludeAnalysisTags: true,
  serviceNote: true
}
export const ClientCategory = {
  id: true,
  title: false
}
export const ClientProvider = {
  id: true,
  provider: false,
  role: true,
  client: false
}
export const ClientRole = {
  id: true,
  title: false
}
export const Config = {
  id: true,
  version: false,
  name: false,
  values: false
}
export const ConfigDictionary = {
  id: true,
  name: false,
  key: false,
  dataType: false,
  dataTypeParam: true,
  description: true
}
export const Contact = {
  id: true,
  firstName: false,
  lastName: false,
  email: false,
  jobTitle: true,
  note: true,
  fullName: true,
  clientId: true,
  user: true,
  client: true,
  division: true,
  address: true,
  phoneNumbers: true,
  fullPhoneNumbers: true
}
export const CorticalFile = {
  attFileName: false,
  id: true,
  attContentType: true,
  attFileSize: true,
  tmFile: true
}
export const Department = {
  id: true,
  createdAt: true,
  updatedAt: true,
  title: false
}
export const Division = {
  id: true,
  code: true,
  name: false,
  clientId: false,
  manager: true
}
export const Domain = {
  id: true,
  tag: false,
  titles: true
}
export const DomainTitle = {
  id: true,
  title: false,
  language: false
}
export const Email = {
  id: true,
  email: true
}
export const Error = {
  id: true,
  createdAt: true,
  reference: false,
  origin: false,
  parameters: false,
  stacktrace: false
}
export const FinalFile = {
  id: true,
  attFileName: false,
  attContentType: true,
  attFileSize: true
}
export const FinalFilesZip = {
  id: true,
  requestId: false,
  zipFileName: true,
  zipContentType: true,
  zipFileSize: true,
  taskExecutions: true
}
export const GeneralParameter = {
  id: true,
  serviceNote: true
}
export const GlobalCalendar = {
  id: true,
  createdAt: true,
  updatedAt: true,
  name: false,
  recurrentCalendarDates: true,
  specificCalendarDates: true
}
export const ImportLangMapping = {
  id: true,
  equivalence: false,
  language: true
}
export const Industry = {
  id: true,
  title: false
}
export const Invoice = {
  id: true,
  client: false,
  contact: false,
  invoiceDate: false,
  invoiceNo: true,
  invoiceTotal: false,
  invoiceGrandTotal: false,
  blocked: false,
  sent: false,
  filed: false,
  invoiceType: false,
  billingAddress: true,
  deliveryAddress: true,
  invoiceRequests: true
}
export const InvoiceCurrency = {
  id: true,
  createdAt: true,
  updatedAt: true,
  title: false
}
export const InvoiceRequest = {
  id: true,
  position: false,
  invoice: false,
  request: false,
  invoiceUnits: true
}
export const InvoiceType = {
  id: true,
  name: false,
  titles: true
}
export const InvoiceTypeTitle = {
  id: true,
  title: false,
  language: false
}
export const InvoiceUnit = {
  id: true,
  amount: false,
  rate: false,
  unit: false
}
export const Language = {
  id: true,
  iso639_1: false,
  active: false
}
export const LocalizedText = {
  id: true,
  language: false,
  content: false
}
export const Message = {
  id: true,
  type: false,
  messageTemplate: true
}
export const MessageHistory = {
  id: true,
  createdAt: true,
  type: false,
  from: false,
  to: false,
  subject: false,
  body: false,
  msgState: false,
  msgStateDetails: true,
  language: false,
  destProvider: true,
  destContact: true,
  destUser: true,
  provider: true,
  contact: true,
  user: true,
  client: true,
  notification: true,
  request: true
}
export const MessageTemplate = {
  id: true,
  subject: false,
  body: false,
  language: true
}
export const Note = {
  id: true,
  createdAt: true,
  updatedAt: true,
  note: false,
  systemGenerated: true,
  recipient: false,
  isCurrentUserAuthor: true,
  author: true,
  requestId: false
}
export const Notification = {
  id: true,
  type: false,
  language: true,
  notificationEmails: true
}
export const Organization = {
  id: true,
  name: false
}
export const PaymentType = {
  id: true,
  createdAt: true,
  updatedAt: true,
  title: false
}
export const PhoneNumber = {
  id: true,
  type: true,
  number: false
}
export const PresenceType = {
  id: true,
  createdAt: true,
  updatedAt: true,
  workFactor: false,
  active: false,
  color: false,
  category: true,
  descriptionRequired: false,
  defaultDuration: true,
  titles: true
}
export const PresenceTypeTitle = {
  id: true,
  title: false,
  language: false
}
export const Priority = {
  id: true,
  title: false
}
export const Project = {
  id: true,
  title: false
}
export const Provider = {
  id: true,
  fullName: true,
  user: true,
  domains: true,
  clients: true,
  services: true,
  translationLanguages: true,
  type: false,
  teams: true,
  email: false,
  fullPhoneNumbers: true,
  firstName: false,
  lastName: false,
  wordsPerHour: true,
  includeStatistics: false,
  requestQaReport: false,
  note: true,
  terminationDate: true,
  secondaryUser: false,
  organization: true,
  divisions: true,
  phoneNumbers: true,
  addresses: true,
  schedulerResources: true
}
export const QaFile = {
  id: true,
  qaFileName: false,
  qaFileFileSize: true,
  qaFileContentType: true
}
export const QualityAssurance = {
  id: true,
  createdAt: true,
  updatedAt: true,
  rating: false,
  comment: false,
  draft: false,
  step: false,
  author: true,
  qaFile: true,
  notifyEmail: false
}
export const Rate = {
  id: true,
  position: false,
  words: false,
  hours: false,
  priority: true
}
export const RateSchema = {
  id: true,
  name: false,
  rates: true
}
export const RecurrentCalendarDate = {
  id: true,
  name: false,
  expression: false
}
export const Request = {
  id: true,
  abort: true,
  coordination: true,
  billing: true,
  deliveryAt: true,
  reqId: true,
  status: true,
  refNo: true,
  title: true,
  invoices: true,
  attachFiles: true,
  po: true,
  confidential: true,
  comments: true,
  extractComments: true,
  client: true,
  contact: true,
  domain: true,
  division: true,
  manager: true,
  manager2: true,
  priority: true,
  project: true,
  contacts: true,
  providers: true,
  requestFiles: true,
  requestWorks: true,
  createdAt: true,
  updatedAt: true,
  deliveredAt: true,
  firstDeliveredAt: true,
  clientWords: true,
  brocoRef: true,
  wordsTodo: true,
  hoursTodo: true,
  wordsDone: true,
  hoursDone: true,
  delivered: true,
  manuallyDelivered: true,
  createdBy: true,
  requestDeliveryChanges: true,
  tmRepSegs: true,
  requestGroups: true,
  requestDeliveries: true,
  notes: true,
  taskExecutions: true
}
export const RequestDelivery = {
  id: true,
  createdAt: true,
  status: false,
  method: false,
  deliveredBy: true,
  note: true,
  requestId: false,
  deliveredFiles: true
}
export const RequestDeliveryChange = {
  id: true,
  createdAt: true,
  deliveryAt: false,
  role: false
}
export const RequestFile = {
  ref: false,
  reqFileName: false,
  internal: false,
  id: true,
  language: true,
  reqContentType: true,
  reqFileSize: true,
  tmFile: true,
  tmFlowFiles: true,
  workflows: true,
  tmFlows: true,
  finalFiles: true,
  request: true
}
export const RequestGroup = {
  id: true,
  title: true,
  relayed: false,
  requestGroupRelation: true
}
export const RequestGroupRelation = {
  id: true,
  position: true,
  requestGroup: false,
  request: false
}
export const RequestWork = {
  id: true,
  workType: false,
  sourceLanguage: false,
  targetLanguages: true
}
export const SchedulerResource = {
  id: true,
  calendarIntervalRanges: true
}
export const SecurityRole = {
  id: true,
  type: false
}
export const Service = {
  id: true,
  active: false,
  color: false,
  wordsValueRatio: true,
  hoursRatio: true,
  titles: true
}
export const ServiceNote = {
  id: true,
  note: false,
  language: true
}
export const ServiceTitle = {
  id: true,
  title: false,
  language: false
}
export const SpecificCalendarDate = {
  id: true,
  name: false,
  startDateTime: false,
  endDateTime: false
}
export const Step = {
  id: true,
  position: false,
  requestAt: false,
  startedAt: true,
  finishedAt: true,
  billable: false,
  external: false,
  plannedHours: true,
  words: true,
  hours: true,
  note: true,
  status: false,
  assignmentStatus: true,
  service: false,
  language: false,
  provider: true,
  stepFiles: true,
  workflow: false,
  stepWorks: true
}
export const StepFile = {
  attFileName: false,
  main: false,
  final: false,
  clean: false,
  id: true,
  updatedAt: true,
  attContentType: true,
  attFileSize: true,
  version: true,
  tmFile: true,
  stepFileHistory: true
}
export const StepFileHistory = {
  id: true,
  updatedAt: true,
  attFileName: false,
  attContentType: true,
  attFileSize: true,
  version: false,
  stepFile: true
}
export const StepWork = {
  id: true,
  workAt: false,
  words: false,
  hours: false,
  stepId: false
}
export const TagConfig = {
  id: true,
  model: false,
  property: false,
  main: false,
  suffix: true
}
export const TaskExecution = {
  id: true,
  createdAt: true,
  updatedAt: true,
  taskName: false,
  status: false,
  name: false,
  parameters: true,
  duration: true,
  progress: true,
  tmFileId: true,
  error: true,
  request: true,
  tmBitext: true
}
export const TaxSchema = {
  id: true,
  createdAt: true,
  updatedAt: true,
  title: false
}
export const Team = {
  id: true,
  titles: true
}
export const TeamTitle = {
  id: true,
  title: false,
  language: false
}
export const TerminoImport = {
  id: true,
  createdAt: true,
  status: false,
  note: false,
  format: false,
  tmTags: true,
  metadata: true,
  importLangMappings: true,
  terminoImportFiles: true,
  progress: true,
  createdBy: true,
  taskExecutions: true
}
export const TerminoImportConversion = {
  id: true,
  terminoHash: false,
  contentHash: false,
  tmTermino: false,
  terminoImportFile: false
}
export const TerminoImportFile = {
  id: true,
  importFileName: false,
  importFileSize: true,
  importContentType: true,
  progress: true,
  status: false,
  terminoImportId: false,
  terminoImportConversions: true
}
export const TimeZone = {
  id: true,
  name: false,
  IanaTzIdentifier: false
}
export const TmBitext = {
  id: true,
  createdAt: true,
  updatedAt: true,
  sourceFileName: false,
  targetFileName: false,
  status: false,
  tracking: false,
  source: true,
  active: false,
  deleted: false,
  verified: false,
  bitextFileName: true,
  bitextContentType: true,
  bitextFileSize: true,
  nbOrphans: true,
  nbSegments: true,
  hold: true,
  note: true,
  progress: true,
  bidirection: true,
  preAligned: true,
  sourceLanguage: false,
  targetLanguage: false,
  sourceFile: true,
  targetFile: true,
  request: true,
  finalFile: true,
  requestFile: true,
  provider: true,
  bitextImportConversion: true,
  tmTags: true,
  taskExecutions: true
}
export const TmDefaultAnalysisTag = {
  id: true,
  tmTag: false
}
export const TmDefaultAssetTag = {
  id: true,
  tmTag: false
}
export const TmDefaultExcludeAnalysisTag = {
  id: true,
  tmTag: false
}
export const TmFile = {
  id: true,
  languageId: false,
  wordCount: true,
  txtFileName: true,
  txtContentType: true,
  txtFileSize: true,
  segsFileName: true,
  segsContentType: true,
  segsFileSize: true,
  originContentType: true,
  originFileName: true,
  originFileSize: true,
  taskExecutions: true
}
export const TmFlow = {
  id: true,
  extractComments: true,
  bilingualTable: true,
  getTmPretran: true,
  getTmRepSeg: true,
  getTmSimProcess: true,
  getTmQa: true,
  getTmBitext: true,
  title: true,
  flag: true,
  fuzzyThreshold: true,
  fieldIsolation: true,
  replaceFuzzy: true,
  pdfMode: true,
  possibleExtractParamsString: true,
  sourceLanguage: true,
  targetLanguage: true,
  requestFile: true,
  sourceFile: true,
  targetFile: true,
  bilingualSet: true,
  analysisTags: true,
  assetsTags: true,
  excludedTags: true,
  tmPretrans: true,
  tmRepSegs: true,
  tmSimProcesses: true,
  tmBitexts: true
}
export const TmPretran = {
  id: true,
  createdAt: true,
  nbExact: true,
  nbClose: true,
  nbFuzzy: true,
  resFileName: true,
  resContentType: true,
  resFileSize: true,
  resDataFileName: true,
  resDataContentType: true,
  resDataFileSize: true,
  nbNewWords: true,
  taskExecutions: true
}
export const TmRepSeg = {
  id: true,
  createdAt: true,
  resFileName: true,
  resContentType: true,
  resFileSize: true,
  resDataFileName: true,
  resDataContentType: true,
  resDataFileSize: true,
  toTranslate: true,
  translated: true,
  taskExecutions: true,
  active: false,
  request: true,
  tmRepSegPreviews: true
}
export const TmRepSegPreview = {
  id: true,
  previewFileName: true,
  previewContentType: true,
  previewFileSize: true,
  request: false,
  tmFile: false,
  taskExecutions: true
}
export const TmSimProcess = {
  id: true,
  createdAt: true,
  updatedAt: true,
  max: true,
  requestFile: true,
  tmSimProcessEntries: true,
  taskExecutions: true
}
export const TmSimProcessEntry = {
  id: true,
  createdAt: true,
  updatedAt: true,
  nbWords: false,
  requestFile: false
}
export const TmTag = {
  id: true,
  name: false,
  main: true,
  suffix: true,
  note: true,
  universal: true,
  isProtected: true,
  type: false,
  originId: true,
  createdAt: true,
  creator: true
}
export const TmTermino = {
  id: true,
  createdAt: true,
  updatedAt: true,
  tracking: false,
  tmTerminoTerms: true,
  tmTags: true,
  active: false,
  validated: false,
  sources: true,
  notes: true,
  createdBy: true,
  terminoImportConversion: true
}
export const TmTerminoTerm = {
  id: true,
  tmTerminoTermLangs: true
}
export const TmTerminoTermLang = {
  id: true,
  text: false,
  status: true,
  language: false,
  variants: true,
  proscribed: false
}
export const TmTerminoVariant = {
  id: true,
  text: false,
  status: true
}
export const User = {
  id: true,
  email: false,
  firstName: false,
  lastName: false,
  avatarFileName: true,
  active: false,
  contact: true,
  provider: true,
  securityRole: true,
  language: true,
  fullName: true
}
export const Workflow = {
  id: true,
  words: true,
  hours: true,
  note: true,
  requestFileId: false,
  steps: true,
  targetLanguage: true,
  requestFile: false
}
export const WorkflowTemplate = {
  id: true,
  title: false,
  workflowTemplateServices: true
}
export const WorkflowTemplateService = {
  id: true,
  position: false,
  billable: false,
  service: false,
  provider: true
}
export const WorkType = {
  id: true,
  active: false,
  titles: true,
  baseWorkType: true,
  defaultWorkflowTemplate: true
}
export const WorkTypeTitle = {
  id: true,
  title: false,
  language: false,
  workType: false
}
export const InvoiceNumber = {}
export const RequestReqId = {}
export const Revision = {}
export const SchedulerAssignment = {}
export const SchedulerEvent = {}
export const TmAnalysis = {}
export const TmSegmentationException = {}
export const TmTerminoHistory = {}
export const Tracking = {}
export const models: Record<string, any> = {
  Address: Address,
  BilingualSet: BilingualSet,
  BillingType: BillingType,
  BitextImport: BitextImport,
  BitextImportConversion: BitextImportConversion,
  BitextImportFile: BitextImportFile,
  BusinessLine: BusinessLine,
  CalendarInterval: CalendarInterval,
  CalendarIntervalRange: CalendarIntervalRange,
  Client: Client,
  ClientCategory: ClientCategory,
  ClientProvider: ClientProvider,
  ClientRole: ClientRole,
  Config: Config,
  ConfigDictionary: ConfigDictionary,
  Contact: Contact,
  CorticalFile: CorticalFile,
  Department: Department,
  Division: Division,
  Domain: Domain,
  DomainTitle: DomainTitle,
  Email: Email,
  Error: Error,
  FinalFile: FinalFile,
  FinalFilesZip: FinalFilesZip,
  GeneralParameter: GeneralParameter,
  GlobalCalendar: GlobalCalendar,
  ImportLangMapping: ImportLangMapping,
  Industry: Industry,
  Invoice: Invoice,
  InvoiceCurrency: InvoiceCurrency,
  InvoiceRequest: InvoiceRequest,
  InvoiceType: InvoiceType,
  InvoiceTypeTitle: InvoiceTypeTitle,
  InvoiceUnit: InvoiceUnit,
  Language: Language,
  LocalizedText: LocalizedText,
  Message: Message,
  MessageHistory: MessageHistory,
  MessageTemplate: MessageTemplate,
  Note: Note,
  Notification: Notification,
  Organization: Organization,
  PaymentType: PaymentType,
  PhoneNumber: PhoneNumber,
  PresenceType: PresenceType,
  PresenceTypeTitle: PresenceTypeTitle,
  Priority: Priority,
  Project: Project,
  Provider: Provider,
  QaFile: QaFile,
  QualityAssurance: QualityAssurance,
  Rate: Rate,
  RateSchema: RateSchema,
  RecurrentCalendarDate: RecurrentCalendarDate,
  Request: Request,
  RequestDelivery: RequestDelivery,
  RequestDeliveryChange: RequestDeliveryChange,
  RequestFile: RequestFile,
  RequestGroup: RequestGroup,
  RequestGroupRelation: RequestGroupRelation,
  RequestWork: RequestWork,
  SchedulerResource: SchedulerResource,
  SecurityRole: SecurityRole,
  Service: Service,
  ServiceNote: ServiceNote,
  ServiceTitle: ServiceTitle,
  SpecificCalendarDate: SpecificCalendarDate,
  Step: Step,
  StepFile: StepFile,
  StepFileHistory: StepFileHistory,
  StepWork: StepWork,
  TagConfig: TagConfig,
  TaskExecution: TaskExecution,
  TaxSchema: TaxSchema,
  Team: Team,
  TeamTitle: TeamTitle,
  TerminoImport: TerminoImport,
  TerminoImportConversion: TerminoImportConversion,
  TerminoImportFile: TerminoImportFile,
  TimeZone: TimeZone,
  TmBitext: TmBitext,
  TmDefaultAnalysisTag: TmDefaultAnalysisTag,
  TmDefaultAssetTag: TmDefaultAssetTag,
  TmDefaultExcludeAnalysisTag: TmDefaultExcludeAnalysisTag,
  TmFile: TmFile,
  TmFlow: TmFlow,
  TmPretran: TmPretran,
  TmRepSeg: TmRepSeg,
  TmRepSegPreview: TmRepSegPreview,
  TmSimProcess: TmSimProcess,
  TmSimProcessEntry: TmSimProcessEntry,
  TmTag: TmTag,
  TmTermino: TmTermino,
  TmTerminoTerm: TmTerminoTerm,
  TmTerminoTermLang: TmTerminoTermLang,
  TmTerminoVariant: TmTerminoVariant,
  User: User,
  Workflow: Workflow,
  WorkflowTemplate: WorkflowTemplate,
  WorkflowTemplateService: WorkflowTemplateService,
  WorkType: WorkType,
  WorkTypeTitle: WorkTypeTitle,
  InvoiceNumber: InvoiceNumber,
  RequestReqId: RequestReqId,
  Revision: Revision,
  SchedulerAssignment: SchedulerAssignment,
  SchedulerEvent: SchedulerEvent,
  TmAnalysis: TmAnalysis,
  TmSegmentationException: TmSegmentationException,
  TmTerminoHistory: TmTerminoHistory,
  Tracking: Tracking
}
