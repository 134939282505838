import useTheme from "@mui/material/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"
import React, { useMemo } from "react"
import ReactPerfectScrollbar from "react-perfect-scrollbar"
import styled, { css } from "styled-components"

import SidebarNavList from "@/components/sidebar/SidebarNavList"
import usePermissions, {
  hasPermissionRight,
  hasPermissionSubsetRight,
} from "@/hooks/usePermissions"
import { RolePolicyModel } from "@/shared/policies/types"
import { SidebarItemsType, SidebarMenuType } from "@/types/sidebar"

const baseScrollbar = css`
  height: 100%;
  background-color: ${(props) => props.theme.sidebar.background};
`

const Scrollbar = styled.div`
  ${baseScrollbar}
`

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`

type SidebarNavProps = {
  items: SidebarItemsType[]
}

const reduceSidebarMenuItems = (
  items: SidebarMenuType[],
  permissions: RolePolicyModel,
) => {
  const result: SidebarMenuType[] = []

  let nbVisible = 0
  let latestIsSeparator = true

  for (const item of items) {
    if (item.separator && !latestIsSeparator) {
      result.push(item)
      latestIsSeparator = true
      continue
    }

    if (item.subMenu) {
      const subMenuItems = reduceSidebarMenuItems(item.subMenu, permissions)
      if (subMenuItems.length) {
        result.push({
          ...item,
          subMenu: subMenuItems,
        })
        latestIsSeparator = false
        nbVisible++
      }
      continue
    }

    if (item.href && item.model && item.right) {
      const hasPermission = item.subset
        ? hasPermissionSubsetRight(
            item.model,
            item.right,
            item.subset,
            permissions,
          )
        : hasPermissionRight(item.model, item.right, permissions)

      const hrefToApply = hasPermission ? item.href : ""

      if (hrefToApply) {
        result.push(item)
        latestIsSeparator = false
        nbVisible++
      }
    }
  }

  if (result.length && result[result.length - 1].separator) {
    result.splice(result.length - 1, 1)
  }

  return nbVisible ? result : []
}

const reduceSidebarItems = (
  items: SidebarItemsType[],
  permissions: RolePolicyModel,
) => {
  const result: SidebarItemsType[] = []

  for (const item of items) {
    if (item.model && item.right && item.href) {
      const hasPermission = item.subset
        ? hasPermissionSubsetRight(
            item.model,
            item.right,
            item.subset,
            permissions,
          )
        : hasPermissionRight(item.model, item.right, permissions)

      const hrefToApply = hasPermission ? item.href : ""

      const visibleItems = item.menu?.length
        ? reduceSidebarMenuItems(item.menu, permissions)
        : []

      if (visibleItems.length || hrefToApply) {
        const { model, right, ...itemRest } = item
        const clonedItem: SidebarItemsType = {
          ...itemRest,
          href: hrefToApply,
          menu: visibleItems,
        }
        result.push(clonedItem)
      }
    } else {
      const visibleItems = item.menu?.length
        ? reduceSidebarMenuItems(item.menu, permissions)
        : []

      if (visibleItems.length) {
        const clonedItem: SidebarItemsType = {
          ...item,
          menu: visibleItems,
        }
        result.push(clonedItem)
      }
    }
  }

  return result
}

const SidebarNav: React.FC<SidebarNavProps> = ({ items }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))
  const permissions = usePermissions()

  const ScrollbarComponent = (
    matches ? PerfectScrollbar : Scrollbar
  ) as React.ElementType

  const visibleItems = useMemo(
    () => reduceSidebarItems(items, permissions),
    [items, permissions],
  )

  return (
    <ScrollbarComponent>
      <SidebarNavList items={visibleItems} />
    </ScrollbarComponent>
  )
}

export default SidebarNav
