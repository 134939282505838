import { cloneDeep } from "lodash"
import { useContext, useMemo } from "react"

import { ConfigContext } from "@/contexts/ConfigContext"
import { mergeSubset, mergeSubsetAttributes } from "@/shared/policies"
import { ModelRights, Models, RolePolicyModel } from "@/shared/policies/types"

const usePermissions = () => {
  const context = useContext(ConfigContext)
  return context?.permissions ?? {}
}

export const useAttributesPermissions = (
  mode: string,
  model: string,
  currentSubset?: string | null,
) => {
  const permissions = usePermissions()

  const attributesPermissions = useMemo(() => {
    const modelPermissions = (permissions as any)[model]
    if (!modelPermissions) return

    let attributesPermissions: any = undefined

    if (mode === "create") {
      // Get first with write rights
      for (const subset of Object.entries(modelPermissions)) {
        const subsetValue = subset[1] as any
        if (["Create", "Delete", "Admin"].includes(subsetValue.rights ?? "")) {
          attributesPermissions = subsetValue.attributes
          if (subset[0] === "class") {
            break
          }
        }
      }
    } else if (currentSubset) {
      // Get current subset
      const subset = (modelPermissions as any)[currentSubset]
      const rights = ["Write", "Create", "Delete", "Admin"]
      if (mode === "show") {
        rights.push("Read")
      }
      if (currentSubset !== "class" && rights.includes(subset?.rights ?? "")) {
        const mergedAttributes = cloneDeep(subset)
        mergeSubsetAttributes(
          modelPermissions.class?.attributes ?? {},
          mergedAttributes,
          model,
        )
        attributesPermissions = mergedAttributes.attributes
      } else {
        if (!attributesPermissions) {
          const classSubset = (modelPermissions as any)["class"]
          if (
            rights.includes(classSubset?.rights ?? "") &&
            classSubset.attributes
          ) {
            attributesPermissions = classSubset.attributes
          }
        }
      }
    }

    return attributesPermissions
  }, [mode, model, currentSubset, permissions])

  return attributesPermissions
}

const rightsOrder: ModelRights[] = [
  "Read",
  "Write",
  "Create",
  "Delete",
  "Admin",
]

const getGreaterRight = (r1: any, r2: any): ModelRights => {
  return rightsOrder.indexOf(r1) > rightsOrder.indexOf(r2) ? r1 : r2
}

export function highestAttributeRight(
  attributeRights: Record<string, ModelRights>,
) {
  const right = attributeRights?.right
  const attribute = attributeRights
    ? Object.values(attributeRights).reduce((prev: string, curr: string) => {
        return getGreaterRight(prev, curr)
      }, "")
    : ""

  return getGreaterRight(right, attribute)
}

export const canShow = (
  fieldKey: string,
  attributesPermissions: any,
): boolean => {
  if (typeof attributesPermissions === "string") {
    return true
  } else if (typeof attributesPermissions === "object") {
    const attrPermission = attributesPermissions[fieldKey]
    return Boolean(attrPermission)
  }

  return false
}

export const hasPermissionRight = <K extends keyof Models>(
  model: K,
  right: ModelRights,
  permissions: RolePolicyModel,
) => {
  const modelPermissions = permissions[model]
  if (!modelPermissions) return false

  const availableRights = rightsOrder.slice(rightsOrder.indexOf(right))

  for (const subset of Object.entries(modelPermissions)) {
    const subsetValue = subset[1] as any
    if (availableRights.includes(subsetValue.rights)) {
      return true
    }
  }

  return false
}

export const hasPermissionSubsetRight = <K extends keyof Models>(
  model: K,
  right: ModelRights,
  subset: Models[K]["subsets"],
  permissions: RolePolicyModel,
) => {
  const modelPermissions = permissions[model]?.[subset]
  if (!modelPermissions?.rights) return false

  const availableRights = rightsOrder.slice(rightsOrder.indexOf(right))
  return availableRights.includes(modelPermissions.rights)
}

export function usePermissionsRight<K extends keyof Models>(
  model: K,
  right: ModelRights,
  throwable?: boolean,
) {
  const permissions = usePermissions()

  const hasRight = useMemo(() => {
    const hasRight = hasPermissionRight(model, right, permissions)
    if (hasRight) {
      return true
    }

    if (throwable) {
      throw new Error(`Permission denied: ${model} ${right}`)
    }

    return false
  }, [model, right, throwable, permissions])

  return hasRight
}

export function usePermissionsRightSubset<K extends keyof Models>(
  model: K,
  right: ModelRights,
  subset?: Models[K]["subsets"] | null,
  throwable?: boolean,
) {
  const permissions = usePermissions()

  const hasRight = useMemo(() => {
    const modelPermissions = permissions[model] as any

    if (!modelPermissions) {
      return false
    }

    const availableRights = rightsOrder.slice(rightsOrder.indexOf(right))
    const subsets = Object.entries(modelPermissions)

    if (
      subsets.some(([key, value]: [string, any]) => {
        return key === "class" && availableRights.includes(value.attributes)
      })
    ) {
      return true
    }

    let subsetPermissions = modelPermissions[subset] ?? {}

    if (
      subset &&
      modelPermissions[subset] &&
      modelPermissions.class &&
      subset !== "class"
    ) {
      const mergedPolicy: any = { [model]: {} }
      mergedPolicy[model][subset] = cloneDeep(subsetPermissions)
      mergeSubset(modelPermissions.class, mergedPolicy, model, subset)
      subsetPermissions = mergedPolicy[model][subset]
    }

    if (availableRights.includes(subsetPermissions.rights)) {
      return true
    }

    if (throwable) {
      throw new Error(`Permission denied: ${model} ${right}`)
    }

    return false
  }, [model, right, throwable, subset, permissions])

  return hasRight
}

export default usePermissions
