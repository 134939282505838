import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import Button from "@/components/Button"
import { useStopVirtualAccessMutation } from "@/graphql"
import useAuth from "@/hooks/useAuth"
import useUIConfig from "@/hooks/useUIConfig"

const StopVirtualAccessButton: React.FC = () => {
  const { t } = useTranslation()
  const { getToken, forceRefreshAvatar } = useAuth()
  const navigate = useNavigate()
  const [stopVirtualAccess] = useStopVirtualAccessMutation()
  const { forceRefreshUiConfig } = useUIConfig()

  const clickHandler = useCallback(async () => {
    await stopVirtualAccess()
    await getToken(undefined, true)
    forceRefreshUiConfig()
    forceRefreshAvatar()
    navigate("/")
  }, [
    getToken,
    navigate,
    stopVirtualAccess,
    forceRefreshAvatar,
    forceRefreshUiConfig,
  ])

  return (
    <Button color="error" onClick={clickHandler}>
      {t("VirtualAccess.Stop")}
    </Button>
  )
}

export default StopVirtualAccessButton
