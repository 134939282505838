import React, { useCallback, useEffect, useState } from "react"

import { useGetFrontEndConfigQuery } from "@/graphql"
import useUIConfig from "@/hooks/useUIConfig"
import { readJson } from "@/services/jsonTransform"

const UiConfig: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { setUiConfig, setForceRefreshUiConfig } = useUIConfig()

  const { data, refetch } = useGetFrontEndConfigQuery()
  const [initialized, setInitialized] = useState(false)

  const forceRefreshUiConfig = useCallback(async () => {
    refetch()
  }, [refetch])

  useEffect(() => {
    for (const config of data?.getFrontEndConfig?.configs || []) {
      if (config) config.values = readJson(config.values)
    }
    setUiConfig(data?.getFrontEndConfig)
    if (data?.getFrontEndConfig) {
      setInitialized(true)
    } else {
      setInitialized(false)
    }
  }, [data, setUiConfig, setInitialized])

  useEffect(() => {
    setForceRefreshUiConfig(() => forceRefreshUiConfig)
  }, [forceRefreshUiConfig, setForceRefreshUiConfig])

  if (!initialized) {
    return <></>
  }

  return children
}

export default UiConfig
