import React from "react"

import PermissionGuard from "@/components/PermissionGuard"
import SidebarNavListItem from "@/components/sidebar/SidebarNavListItem"
import { SidebarItemsType } from "@/types/sidebar"

type SidebarNavListProps = {
  items: SidebarItemsType[]
}

const SidebarNavList: React.FC<SidebarNavListProps> = (props) => {
  const { items } = props

  const navItems = items.map((item, index) => {
    if (item.model && item.right) {
      return (
        <React.Fragment key={index}>
          <PermissionGuard empty model={item.model} right={item.right}>
            <SidebarNavListItem
              href={item.href}
              title={item.title}
              icon={item.icon}
              model={item.model}
              right={item.right}
              menu={item.menu}
            />
          </PermissionGuard>
        </React.Fragment>
      )
    } else {
      return (
        <SidebarNavListItem
          key={index}
          href={item.href}
          title={item.title}
          icon={item.icon}
          menu={item.menu}
        />
      )
    }
  })

  return <>{navItems}</>
}

export default SidebarNavList
