import Loadable from "@loadable/component"

export const Home = Loadable(() => import("./home"))
export const Page404 = Loadable(() => import("./auth/Page404"))

export const SignIn = Loadable(() => import("./auth/SignIn"))
export const ChangePassword = Loadable(() => import("./auth/ChangePassword"))
export const NewPassword = Loadable(() => import("./auth/NewPassword"))
export const ForgotPassword = Loadable(() => import("./auth/ForgotPassword"))
export const ResetPassword = Loadable(() => import("./auth/ResetPassword"))

export const Requests = Loadable(() => import("./requests/List"))
export const ShowRequest = Loadable(() => import("./requests/Show"))
export const EditRequest = Loadable(() => import("./requests/Edit"))
export const NewRequest = Loadable(() => import("./requests/New"))
export const ShowRequestMessage = Loadable(
  () => import("./requests/Show/MessageList/Message"),
)

export const AdvancedSearch = Loadable(() => import("./advancedSearch/index"))

export const Clients = Loadable(() => import("./clients/List"))
export const ShowClient = Loadable(() => import("./clients/Show"))
export const NewClient = Loadable(() => import("./clients/New"))
export const EditClient = Loadable(() => import("./clients/Edit"))

export const BillingTypes = Loadable(() => import("./billingTypes/List"))
export const TaxSchemas = Loadable(() => import("./taxSchemas/List"))
export const PaymentTypes = Loadable(() => import("./paymentTypes/List"))
export const InvoiceCurrencies = Loadable(
  () => import("./invoiceCurrencies/List"),
)
export const BusinessLines = Loadable(() => import("./businessLines/List"))
export const Departments = Loadable(() => import("./departments/List"))

export const Errors = Loadable(() => import("./errors/List"))
export const ShowError = Loadable(() => import("./errors/Show"))

export const Messages = Loadable(() => import("./messages/List"))
export const ShowMessage = Loadable(() => import("./messages/Show"))
export const EditMessage = Loadable(() => import("./messages/Edit"))

export const Notifications = Loadable(() => import("./notifications/List"))
export const ShowNotification = Loadable(() => import("./notifications/Show"))
export const EditNotification = Loadable(() => import("./notifications/Edit"))
export const NewNotification = Loadable(() => import("./notifications/New"))

export const ShowPretrans = Loadable(() => import("./pretrans/Show"))

export const ShowRepSegs = Loadable(() => import("./repsegs/Show"))

export const ShowSimilarities = Loadable(() => import("./similarities/Show"))

export const ProvidersList = Loadable(() => import("./providers/List"))
export const NewProvider = Loadable(() => import("./providers/New"))
export const ShowProvider = Loadable(() => import("./providers/Show"))
export const EditProvider = Loadable(() => import("./providers/Edit"))

export const Settings = Loadable(() => import("./settings/List"))
export const EditSettings = Loadable(() => import("./settings/Edit"))

export const NewSearch = Loadable(() => import("./search/New"))

export const Terminos = Loadable(() => import("./terminos/List"))
export const NewTermino = Loadable(() => import("./terminos/New"))
export const EditTermino = Loadable(() => import("./terminos/Edit"))
export const ShowTermino = Loadable(() => import("./terminos/Show"))

export const Users = Loadable(() => import("./users/List"))
export const EditUser = Loadable(() => import("./users/Edit"))
export const NewUser = Loadable(() => import("./users/New"))
export const ShowUser = Loadable(() => import("./users/Show"))
export const Profile = Loadable(() => import("./profile/Show"))
export const EditProfile = Loadable(() => import("./profile/Edit"))

export const Contacts = Loadable(() => import("./contacts/Contacts"))
export const EditContact = Loadable(() => import("./contacts/Edit"))
export const NewContact = Loadable(() => import("./contacts/New"))
export const ShowContact = Loadable(() => import("./contacts/Show"))

export const TagConfigs = Loadable(() => import("./tagConfigs/List"))
export const NewTagConfig = Loadable(() => import("./tagConfigs/New"))
export const ShowTagConfig = Loadable(() => import("./tagConfigs/Show"))
export const EditTagConfig = Loadable(() => import("./tagConfigs/Edit"))

export const Tags = Loadable(() => import("./tags/List"))
export const ShowTag = Loadable(() => import("./tags/Show"))
export const EditTag = Loadable(() => import("./tags/Edit"))
export const TagAction = Loadable(() => import("./tags/Action"))

export const Bitexts = Loadable(() => import("./bitexts/List"))
export const EditBitext = Loadable(() => import("./bitexts/Edit"))
export const NewBitext = Loadable(() => import("./bitexts/New"))
export const ShowBitext = Loadable(() => import("./bitexts/Show"))

export const BitextImports = Loadable(() => import("./bitextImports/List"))
export const NewBitextImport = Loadable(() => import("./bitextImports/New"))
export const ShowBitextImport = Loadable(() => import("./bitextImports/Show"))

export const TerminoImports = Loadable(() => import("./terminoImports/List"))
export const NewTerminoImport = Loadable(() => import("./terminoImports/New"))
export const ShowTerminoImport = Loadable(() => import("./terminoImports/Show"))

export const Activities = Loadable(() => import("./activities/List"))
export const EditActivity = Loadable(() => import("./activities/Edit"))
export const NewActivity = Loadable(() => import("./activities/New"))
export const ShowActivity = Loadable(() => import("./activities/Show"))

export const Services = Loadable(() => import("./services/List"))
export const EditService = Loadable(() => import("./services/Edit"))
export const NewService = Loadable(() => import("./services/New"))
export const ShowService = Loadable(() => import("./services/Show"))

export const Teams = Loadable(() => import("./teams/List"))
export const EditTeam = Loadable(() => import("./teams/Edit"))
export const NewTeam = Loadable(() => import("./teams/New"))
export const ShowTeam = Loadable(() => import("./teams/Show"))

export const Domains = Loadable(() => import("./domains/List"))
export const EditDomain = Loadable(() => import("./domains/Edit"))
export const NewDomain = Loadable(() => import("./domains/New"))
export const ShowDomain = Loadable(() => import("./domains/Show"))

export const CalendarTemplates = Loadable(
  () => import("./calendarTemplates/List"),
)
export const EditCalendarTemplate = Loadable(
  () => import("./calendarTemplates/Edit"),
)
export const NewCalendarTemplate = Loadable(
  () => import("./calendarTemplates/New"),
)
export const ShowCalendarTemplate = Loadable(
  () => import("./calendarTemplates/Show"),
)

export const WorkflowTemplates = Loadable(
  () => import("./workflowTemplates/List"),
)
export const EditWorkflowTemplate = Loadable(
  () => import("./workflowTemplates/Edit"),
)
export const NewWorkflowTemplate = Loadable(
  () => import("./workflowTemplates/New"),
)
export const ShowWorkflowTemplate = Loadable(
  () => import("./workflowTemplates/Show"),
)

export const TaskExecutions = Loadable(() => import("./taskExecutions/List"))

export const WorkTypes = Loadable(() => import("./workTypes/List"))
export const EditWorkType = Loadable(() => import("./workTypes/Edit"))
export const NewWorkType = Loadable(() => import("./workTypes/New"))
export const ShowWorkType = Loadable(() => import("./workTypes/Show"))

export const ShowServiceNote = Loadable(() => import("./serviceNote/Show"))
export const EditServiceNote = Loadable(() => import("./serviceNote/Edit"))

export const ShowScheduler = Loadable(() => import("./scheduler/Show"))

export const ShowPlanning = Loadable(() => import("./planning/Show"))

export const ShowReport = Loadable(() => import("./reports/Show"))
export const Reports = Loadable(() => import("./reports/List"))
export const AdminReports = Loadable(() => import("./reports/Admin"))
