import IconAdministration from "@/components/icons/IconAdministration"
import IconClients from "@/components/icons/IconClients"
import IconMemory from "@/components/icons/IconMemory"
import IconProviders from "@/components/icons/IconProviders"
import IconRequests from "@/components/icons/IconRequests"
import IconSettings from "@/components/icons/IconSettings"
import { SidebarItemsType, SidebarMenuType } from "@/types/sidebar"

const menuItems = [
  {
    href: "/requests",
    icon: IconRequests,
    title: "Requests",
    model: "Request",
    right: "Read",
    menu: [
      {
        title: "Requests.Title",
        href: "/requests",
        model: "Request",
        right: "Read",
      },
      {
        title: "AdvancedRequestSearch.Title",
        href: "/requestSearch",
        model: "AdvancedRequestSearch",
        right: "Write",
      },
      {
        title: "Configuration",
        subMenu: [
          {
            title: "BusinessLines.Title",
            href: "/businessLines",
            model: "BusinessLine",
            right: "Create",
          },
          {
            title: "Departments.Title",
            href: "/departments",
            model: "Department",
            right: "Create",
          },
        ] as SidebarMenuType[],
      },
    ] as SidebarMenuType[],
  },
  {
    href: "/providers",
    icon: IconProviders,
    title: "Providers",
    model: "Provider",
    right: "Create",
    menu: [
      {
        title: "Providers.Title",
        href: "/providers",
        model: "Provider",
        right: "Create",
      },
      {
        title: "Planning.Title",
        href: "/planning",
        model: "Planning",
        right: "Write",
      },
      {
        title: "Scheduler.Title",
        href: "/scheduler",
        model: "Planning",
        right: "Write",
      },
      {
        title: "Configuration",
        subMenu: [
          {
            title: "Teams.Title",
            href: "/teams",
            model: "Team",
            right: "Create",
          },
          {
            title: "Domains.Title",
            href: "/domains",
            model: "Domain",
            right: "Create",
          },
        ] as SidebarMenuType[],
      },
    ] as SidebarMenuType[],
  },
  {
    href: "/clients",
    model: "Contact",
    right: "Create",
    subset: "class",
    icon: IconClients,
    title: "Clients",
    menu: [
      {
        title: "Clients.Title",
        href: "/clients",
        model: "Client",
        right: "Write",
      },
      {
        title: "Contacts.Title",
        href: "/contacts",
        model: "Contact",
        right: "Create",
        subset: "class",
      },
    ] as SidebarMenuType[],
  },
  {
    href: "/tags",
    icon: IconMemory,
    title: "Echo",
    model: "TmTag",
    right: "Create",
    menu: [
      {
        title: "TmSearch.Title",
        href: "/search",
        model: "TmSearchCustom",
        right: "Read",
      },
      {
        title: "TmBitexts.Title",
        href: "/bitexts",
        model: "TmBitext",
        right: "Read",
      },
      {
        title: "TmTermino.Title",
        href: "/terminos",
        model: "TmTermino",
        right: "Read",
      },
      {
        title: "TmTags.Title",
        href: "/tags",
        model: "TmTag",
        right: "Create",
      },
      {
        title: "Bulk.Title",
        subMenu: [
          {
            href: "/bitextImports",
            title: "Bulk.BitextImport",
            model: "BitextImport",
            right: "Admin",
          },
          {
            title: "Bulk.TerminoImport",
            href: "/terminoImports",
            model: "TerminoImport",
            right: "Admin",
          },
        ] as SidebarMenuType[],
      },
    ] as SidebarMenuType[],
  },
  {
    icon: IconAdministration,
    title: "Administration",
    menu: [
      {
        title: "Users.Title",
        href: "/users",
        model: "User",
        right: "Create",
      },
      {
        separator: true,
      },
      {
        title: "Reports.Title",
        href: "/reports",
        model: "Report",
        right: "Read",
      },
      {
        separator: true,
      },
      {
        title: "Messages.Title",
        href: "/messages",
        model: "Message",
        right: "Write",
      },
      {
        title: "Calendars",
        subMenu: [
          {
            title: "CalendarTemplates.Title",
            href: "/calendarTemplates",
            model: "GlobalCalendar",
            right: "Create",
          },
          {
            title: "Activities.ListTitle",
            href: "/activities",
            model: "PresenceType",
            right: "Create",
          },
        ],
      },
      {
        title: "Billing",
        subMenu: [
          {
            title: "BillingTypes.Title",
            href: "/billingTypes",
            model: "BillingType",
            right: "Create",
          },
          {
            title: "TaxSchemas.Title",
            href: "/taxSchemas",
            model: "TaxSchema",
            right: "Create",
          },
          {
            title: "PaymentTypes.Title",
            href: "/paymentTypes",
            model: "PaymentType",
            right: "Create",
          },
          {
            title: "InvoiceCurrencies.Title",
            href: "/invoiceCurrencies",
            model: "InvoiceCurrencie",
            right: "Create",
          },
        ],
      },
      {
        title: "Processus",
        subMenu: [
          {
            title: "WorkTypes.ListTitle",
            href: "/workTypes",
            model: "WorkType",
            right: "Create",
          },
          {
            title: "Services.ListTitle",
            href: "/services",
            model: "Service",
            right: "Create",
          },
          {
            title: "WorkflowTemplates.ListTitle",
            href: "/workflowTemplates",
            model: "WorkflowTemplate",
            right: "Create",
          },
        ] as SidebarMenuType[],
      },
      {
        title: "GeneralParameters",
        subMenu: [
          {
            title: "ServiceNote.Title",
            href: "/serviceNote/Show",
            model: "ServiceNote",
            right: "Write",
          },
        ] as SidebarMenuType[],
      },
      {
        separator: true,
      },
      {
        title: "TaskExecutions.Title",
        href: "/taskExecutions",
        model: "TaskExecution",
        right: "Admin",
      },
      {
        title: "Errors.Title",
        href: "/errors",
        model: "Error",
        right: "Admin",
      },
    ] as SidebarMenuType[],
  },
  {
    icon: IconSettings,
    title: "Settings",
    menu: [
      {
        title: "Reports.Title",
        href: "/reports/admin",
        model: "Report",
        right: "Admin",
      },
      {
        separator: true,
      },
      {
        title: "Settings.Title",
        href: "/settings",
        model: "Config",
        right: "Write",
      },
      {
        separator: true,
      },
      {
        title: "TagConfigs.Title",
        href: "/tagConfigs",
        model: "TagConfig",
        right: "Write",
      },
      {
        title: "Notifications.Title",
        href: "/notifications",
        model: "Notification",
        right: "Write",
      },
    ] as SidebarMenuType[],
  },
] as SidebarItemsType[]

export default menuItems
