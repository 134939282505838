import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"

type IconButtonProps = MuiIconButtonProps

const IconButton: React.FC<IconButtonProps> = ({
  title,
  children,
  ...props
}) => {
  return props.disabled ? (
    <MuiIconButton {...props}>{children}</MuiIconButton>
  ) : (
    <Tooltip title={title}>
      <MuiIconButton {...props}>{children}</MuiIconButton>
    </Tooltip>
  )
}

export default IconButton
